import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

// core components

class DrutaDownloadView extends React.Component {
    state = {};

    state = {
      idFormModal: false      
    };

    toggleModal = state => {
      this.setState({
        [state]: !this.state[state]
      });
    }

    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
        <section className="section section-lg bg-gradient-success">
          <Modal
                className="modal-dialog-centered"
                isOpen={this.state.idFormModal}
                toggle={() => this.toggleModal("idFormModal")}
                >
                <Row>
                  <Col lg="12">
                  <div className="modal-header">                    
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdlsPqMaHXy49PfL-1EKp_AntihQESBsKYW1zpdV_liEE250Q/viewform?embedded=true" width="1080" height="600" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                  </div>
                  </Col>
                </Row>                
          </Modal>
          <Container>
            <Row className="row-grid justify-content-center ">
              <Col className="text-center" lg="8">
                <h2 className="display-3">
                  Get Ready to lauch your rocketship{" "}
                  <span className="text-deault">
                    using Druta?
                  </span>
                </h2>
                {/* <p className="lead">
                  Cause if you do, it can be yours for FREE. Hit the button
                  below to navigate to Creative Tim where you can find the
                  Design System in React. Start a new project or give an old
                  Bootstrap project a new look!
                </p> */}
                <div className="btn-wrapper">
                  
                  {/* <Button
                    className="mb-3 mb-sm-0"
                    color="primary"
                    href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                  >
                    Download Druta Vahan
                  </Button> */}

                  <Button
                    className="mb-3 mb-sm-0"
                    color="primary"
                    href="http://bit.ly/2ZqJ8Xv"
                  >
                    Download Karma
                  </Button>

                  <Button
                    className="mb-3 mb-sm-0"
                    color="primary"
                    onClick={() => this.toggleModal("idFormModal")}
                  >
                    Get Druta Vahan binary and ID files
                  </Button>
                </div>                
              </Col>
            </Row>
          </Container>
        </section>            
        </>
      );
    }
  }
  
  export default DrutaDownloadView;