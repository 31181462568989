import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";

class DrutaServerless extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />


<section className="section section-lg bg-gradient-primary">
            <Container className="pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Why choose Serverless Methodology?</h2>
                    <div style={{ "text-align" : "center"}}>
                      <img
                            alt="..."
                            className="img-fluid rounded shadow-lg"                            
                            src={require("assets/img/serverless_future.png")}
                          />
                      </div>  
                  <h3 className="h4 text-white font-weight-bold">
                    Because, Its Awesome!
                  </h3>
                  <p className="lead text-white">
                    Serverless is one step closer  to future promise of writing only business logic and runtime taking care of lifetime
                  </p>
                  <p className="lead text-white">
                    Serverles aka Cloud 3.0 is next wave of innovation in cloud computing which provides managed application runtime in addition to middleware platform and operating system layers.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5 justify-content-center">
                <Col lg="4">
                <div style={{ "text-align" : "center"}}>
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  
                  <h5 className="text-white mt-3">Faster Development</h5>
                  <p className="text-white mt-3">
                    Simpler Programming model allows focus on application rather than infrastructure         
                  </p>
                  <p className="text-white mt-3">
                    Composition of application using Building blocks
                  </p>
                  </div>
                </Col>
                <Col lg="4">
                <div style={{ "text-align" : "center"}}>
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Reduced Operating Cost</h5>
                  <p className="text-white mt-3 display-text2">
                  Cloud resources are billed at finer granularity tied to actual usage patterns rather than simple hourly billing
                  </p>
                  <p className="text-white mt-3">
                  Cloud providers manage resource on your behalf                    
                  </p>
                  </div>
                </Col>
                <Col lg="4">
                <div style={{ "text-align" : "center"}}>
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary align-items-center">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Scalablity</h5>
                  <p className="text-white mt-3 display-text4">
                    Serverless applications are in-herently distributed and scalable
                  </p>                  
                  <p className="text-white mt-3 display-text4">
                    Serverless methodology makes developement scalable as quick deployments are possible
                  </p>                  
                  </div>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>                        
            <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaServerless;