import React from "react";
// nodejs library that concatenates classes
import APIGateway from 'react-aws-icons/dist/aws/logo/APIGateway';
import CloudFront from 'react-aws-icons/dist/aws/logo/CloudFront';
import Cognito from 'react-aws-icons/dist/aws/logo/Cognito';
import DynamoDB from 'react-aws-icons/dist/aws/logo/DynamoDB';
import S3 from 'react-aws-icons/dist/aws/logo/S3';
import SES from 'react-aws-icons/dist/aws/logo/SES';
import SNS from 'react-aws-icons/dist/aws/logo/SES';
import SQS from 'react-aws-icons/dist/aws/logo/SQS';
import CloudWatch from 'react-aws-icons/dist/aws/logo/CloudWatch';
import Lambda from 'react-aws-icons/dist/aws/logo/Lambda';
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaContact from "./DrutaContact.jsx";

class DrutaAWS extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />


<section className="section section-lg bg-gradient-primary">
            <Container className="pb-10">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Serverless Applications on AWS Lambda</h2>
                  <p className="lead text-white">
                    Exprienced Team at Druta can help you develop serverless application using Standard AWS Stack in Java/Scala/JavaScript language                    
                  </p>

                  <p className="lead text-white"> Druta can help you with designing your business applications to embrace serverless stack, help you make architectural decisions keeping the cost in mind. We can also help implement the best practices of serverless computing</p>

                  <h3 className="display-text2 text-white">Design, Develop & Deploy</h3>

                  
                </Col>
                </Row>  
                
                <Row className="text-center justify-content-center">
                  <Col>
                  <p className="lead text-white">With the team of strong UI and backend developers, we build applications on serverless platforms. We work closely with clients to understand their technology and design needs. We take care of end to end application development starting from UX to deployment</p>
                  </Col>  
                </Row> 
                <Row className="text-center justify-content-center">
                  <Col lg="6">
                    <h3 className="display-text2 text-white">Front-End</h3>
                  <Row>
                    <Col><div style={{ 'font-size': '3rem' }}>
                      <div class="devicon-android-plain colored" />
                    </div></Col>
                    <Col><div style={{ 'font-size': '3rem' }}>
                      <div class="devicon-react-original colored" />
                    </div></Col>
                    <Col><div style={{ 'font-size': '3rem' }}>
                      <div class="devicon-vuejs-plain-wordmark colored" />
                    </div></Col>
                    <Col><div style={{ 'font-size': '3rem' }}>
                      <div class="devicon-angularjs-plain colored" />
                    </div></Col>
                    <Col><div style={{ 'font-size': '2rem' }}>
                      <img
                          alt="..."
                          className="img-fluid"
                          src={require("assets/img/flutter.svg")}
                        />  
                    </div></Col>
                  </Row>                                                                                                 
                  </Col>
                  <Col lg="6">
                    <h3 className="display-text2 text-white">Backend</h3>
                    <Lambda size={48}/>
                    <APIGateway size={48}/>
                    <CloudFront size={48}/>
                    <Cognito size={48}/>
                    <DynamoDB size={48}/>
                    <S3 size={48}/>
                    <SES size={48}/>
                    <SNS size={48}/>
                    <SQS size={48}/>
                    <CloudWatch size={48}/>
                  </Col>
                </Row>                         
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>                        
          <DrutaContact />
          <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaAWS;