import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaDownloadView from "./DrutaDownloadView.jsx";

class DrutaKarma extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
          <DrutaNavbar />
          <section className="section section-lg bg-success">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <h1 className="text-white">Karma</h1>
                    <p className="text-white lead">
                    Karma is an Serverless Platform As A Service which offers you the functionality of Lambda and API Gateway with better runtime performance and developer productivity
                    <br/><Link to="/druta-showcase#karmasc"><span className="text-white">See in action</span></Link>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="row-grid align-items-center mr-1">
                <Col className="order-md-2" md="12">
                  <Card className=" shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/karma_arch.png")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-primary"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-primary"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg> */}
                      <h4 className="display-3 font-weight-bold text-success">
                      Create serverless apps in minutes
                      </h4>
                      <p className="lead text-italic">
                      Karma is your mini-aws serverless cloud which you can setup in minutes. Karma uses Google Protobuf and GRPC to define services. GRPC interface allows developers to import middleware services and start developing right away while let Karma take care of creating Middleware resources and other tedious AWS tasks.
                      Karma Applets are optimized before deployment in special format (#no-container) then deployed into Karma Cloud
                      </p>
                      <h5 className="display-4 text-success">Supported Middlewares</h5>

                      <Badge color="primary" pill className="mr-1">Dynamodb</Badge>
                      <Badge color="warning" pill className="mr-1">XMLHttpRequest</Badge>
                      <Badge color="secondary" pill className="mr-1">Cloudfront</Badge>
                      <Badge color="sucess" pill className="mr-1">Redis</Badge>
                      <Badge color="danger" pill className="mr-1">AWS DocumentDB</Badge>
                      <Badge color="info" pill className="mr-1">SQL Postgres</Badge>
                      <Badge color="default" pill className="mr-1">Mobile SMS Notification</Badge>
                      <Badge color="primary" pill className="mr-1">Mobile Push Notifications</Badge>

                    </blockquote>
                  </Card>
                </Col>
              </Row>              
              


<Row className="row-grid align-items-center">                
                <Col className="order-md-1" md="6">
                    <Card className="shadow shadow-lg--hover mt-2">
                        <CardBody>
                        <div className="d-flex px-3">
                            <div>
                            <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                <i className="ni ni-satisfied" />
                            </div>
                            </div>
                            <div className="pl-4">
                            <h5 className="display-4 text-success">
                            JavaScript Interface
                            </h5>
                            
                            <p className="lead">Allows you to write strictly typed services declaratively using Protobuf</p>
                            <p className="lead">Interface also provides easy-to-use interface to all declared AWS Services</p>
                            
                            <br/> 
                            <br/> 
                            <br/> 
                            
                            </div>
                        </div>
                        </CardBody>
                    </Card>              
                </Col>
                <Col className="order-md-1" md="6">
                    <Card className="shadow shadow-lg--hover mt-2">
                        <CardBody>
                        <div className="d-flex px-3">
                            <div>
                            <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                <i className="ni ni-satisfied" />
                            </div>
                            </div>
                            <div className="pl-4">
                            <h5 className="display-4 text-success">
                            Middleware Services
                            </h5>
                            
                            <p className="lead">Karma runtime provides easy-to-use API to invokte AWS Middleware services. Check list of supported middlewares below</p>
                            <p className="lead">Karma tools take care of devops task required to manage AWS resources</p>
                            
                            </div>
                        </div>
                        </CardBody>
                    </Card>              
                </Col>
                </Row>
                <Row className="row-grid align-items-center"> 
                <Col className="order-md-1" md="6">
                        <Card className="shadow shadow-lg--hover mt-2">
                            <CardBody>
                            <div className="d-flex px-3">
                                <div>
                                <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                    <i className="ni ni-satisfied" />
                                </div>
                                </div>
                                <div className="pl-4">
                                <h5 className="display-4 text-success">
                                ReactJS Client Generator
                                </h5>
                                
                                <p className="lead">Allows you to create JavaScript reactjs module which you can readily import and start integrating front-end with karma services</p>
                                <p className="lead"> See Examples Applets</p>
                                <p className="lead">Module contains all data models and service stubs. Its similar to what API Gateway gives you (based on OpenAPI specification) but with out-of-the-box ReactJs-Redux integration</p>
                                </div>
                            </div>
                            </CardBody>
                        </Card>               
                    </Col>
                    <Col className="order-md-1" md="6">
                        <Card className="shadow shadow-lg--hover mt-2">
                            <CardBody>
                            <div className="d-flex px-3">
                                <div>
                                <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                    <i className="ni ni-satisfied" />
                                </div>
                                </div>
                                <div className="pl-4">
                                <h5 className="display-4 text-success">
                                Zero Devops
                                </h5>
                                <p className="lead">Complex layers like Kubernetes/Service Mesh are handled for you</p>                                
                                <p className="lead">Option to host entire Karma Platform in your cloud account</p>
                                <p className="lead">CLI provides you pre-defined templates to bootstrap karma applicationallows you to deploy the app to karma runtime  </p>
                                
                                <br/>                              
                                
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                        </Col>
                        
              </Row>
              <Row className="row-grid align-items-center"> 
                    <Col className="order-md-1" md="6">
                        <Card className="shadow shadow-lg--hover mt-2">
                            <CardBody>
                            <div className="d-flex px-3">
                                <div>
                                <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                    <i className="ni ni-satisfied" />
                                </div>
                                </div>
                                <div className="pl-4">
                                <h5 className="display-4 text-success">
                                Development Server
                                </h5>
                                
                                <p className="lead">Local HTTP server to run your applets locally</p>
                                <p className="lead">Postman/Swagger like interface which allows you to invoke deployed services interactively</p>
                                <p className="lead">Local Shell which allows direct interaction with middleware services</p>
                                
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                        </Col>
                        <Col className="order-md-1" md="6">
                        <Card className="shadow shadow-lg--hover mt-2">
                            <CardBody>
                            <div className="d-flex px-3">
                                <div>
                                <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                    <i className="ni ni-satisfied" />
                                </div>
                                </div>
                                <div className="pl-4">
                                <h5 className="display-4 text-success">
                                    In-built   Observability
                                </h5>
                                
                                <p className="lead">Karma collects service invocation statistics and generates dashboards from your service definitions</p>
                                <p className="lead">Framework (no relation to cloudwatch) also allows you to define and embed custom metrics</p>
                                <br/>      <br/>     
                                
                                </div>
                            </div>
                            </CardBody>
                        </Card>               
                </Col>
              </Row>


             
            </Container>
          </section>
          <DrutaDownloadView />
          <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaKarma;