import React from "react";
// nodejs library that concatenates classes

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";

class DrutaShowcase extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />
            <section className="section section-lg bg-gradient-success">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                <h4 className="display-3 text-white">Druta Showcase</h4>

                 <Card className="bg-gradient-warning shadow-lg border-0 mb-5" id="drutavahansc">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        DrutaVahan - CLI Demo Video
                      </h3>
                    </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg="8" >
                        <div style={{ "text-align" : "center"}}>
                            <iframe width="672" height="378" src="https://www.youtube.com/embed/MXsGc68vpXY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </Col>                      
                  </Row>                  
                    <Row>
                    <Col lg="12">
                      <p className="lead text-white mt-3">
                        Demonstration of DrutaVahan - how to bootstrap project, deploy it on AWS, generate client and run it to call deployed services
                      </p>
                    </Col>                    
                  </Row>
                  
                </div>
              </Card>

               <Card className="bg-gradient-warning shadow-lg border-0 mb-5" id="karmasc">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        Karma - CLI Demo Video
                      </h3>
                    </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg="12" >
                        <div style={{ "text-align" : "center"}}>                        
                            <a href="https://asciinema.org/a/263173" target="_blank" rel="noopener noreferrer"><img src="https://asciinema.org/a/263173.svg" width="100%" alt="karmaclidemo"/></a>                        
                        </div>
                      </Col>                      
                  </Row>                  
                    <Row>
                    <Col lg="12">
                      <p className="lead text-white mt-3">
                        Demonstration of Karma - how to register for Karma PAAS, bootstrap project, deploy it on karma, invoked deployed services using karma-client
                      </p>
                    </Col>                    
                  </Row>
                  
                </div>
              </Card>


                <Card className="bg-gradient-warning shadow-lg border-0 mb-5">
                  <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        DrutaFit - Showcase App for Mobile Backend As a Service Blueprint
                      </h3>
                    </Col>
                    </Row>
                    <Row className="align-items-center">
                    <Col lg="6" >
                        <div style={{ "text-align" : "center"}}>
                            <img src={require("assets/img/drutafit/image04.jpg")} height="405px" width="228px" alt="drutafitimg1"/>
                        </div>
                      </Col>
                      <Col lg="6" >
                        <div style={{ "text-align" : "center"}}>
                            <img src={require("assets/img/drutafit/image06.jpg")}  height="405px" width="228px" alt="drutafitimg2"/>
                        </div>
                      </Col>
                  </Row>                  
                    <Row>
                    <Col lg="12">
                      <p className="lead text-white mt-3">
                        <ul>
                            <li>Simple mobile application to track your walking activity targeted at people with desk jobs.</li>
                            <li>App Backend is implemented using DrutaVahan Mobile Backend Blueprint and Application is written using Flutter framework and integrated with backend using DrutaVahan Client for Dart</li>
                            <li>Total backend integration code is about 3000 lines which includes user login/logout/signup and syncing of fitness coins</li>
                            <li>Backend is just a configuaration file with no Java Code</li>
                        </ul>
                      </p>
                    </Col>                    
                  </Row>
                  
                </div>
              </Card>

               

                </Col>
              </Row>
            </Container>
            <DrutaFooter />
          </section>
        </>
      );
    }
  }
  
  export default DrutaShowcase;