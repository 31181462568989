import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

class DrutaNavbar extends React.Component {
    componentDidMount() {
      let headroom = new Headroom(document.getElementById("navbar-main"));
      // initialise
      headroom.init();
    }
    
    render() {
      return (
      <>
        <header>
        <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <h6 className="display-4 text-white">
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-spaceship" />
                    Druta</span>                  
                </h6>
                
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar_global">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                      <h6 className="display-4 text-default">
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-spaceship" />
                          Druta</span>                  
                      </h6>
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Products</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          tag={Link}
                          to="/druta-vahan"                          
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-spaceship" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-warning mb-md-1">
                              Druta Vahana
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Rapid Application Development Toolkit for Serverless
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          tag={Link}
                          to="/druta-karma"                          
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-app" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Karma
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Serverless Platform As A Service
                            </p>
                          </Media>
                        </Media>                        
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Solutions</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          tag={Link}
                            to="/druta-blueprints"
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-album-2" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Druta Vahan Blueprints
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Reference implementations of some of common patterns that can help you jump-start your application
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          tag={Link}
                            to="/druta-aws"                          
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-cloud-upload-96" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Serverless Applications on AWS Lambda
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Experienced Team with all expertise behind DrutaVahana and AWS/Serverless
                            </p>
                          </Media>
                        </Media>  
                        <Media
                          className="d-flex align-items-center"
                          tag={Link}
                            to="/druta-k8s"                          
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-scissors" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Cloud-Native/Serverless Application development on Kubernetes Stack
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Modern high-scale event-driven applications written using serverless methodology
                            </p>
                          </Media>
                        </Media>                         
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Resources</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/druta-how" tag={Link}>
                        Druta Acceleration
                      </DropdownItem>
                      <DropdownItem to="/druta-why-serverless" tag={Link}>
                        Why Serverless?
                      </DropdownItem>        
                      <DropdownItem to="/druta-showcase" tag={Link}>
                        Showcase
                      </DropdownItem>              
                      <DropdownItem to="/druta-downloads" tag={Link}>
                        Downloads
                      </DropdownItem>
                     
                      {/* <DropdownItem to="/druta-login" tag={Link}>
                        Login
                      </DropdownItem>
                      <DropdownItem to="/druta-register" tag={Link}>
                        Register
                      </DropdownItem> */}

                    </DropdownMenu>
                  </UncontrolledDropdown>                                    
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">About Druta</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem to="/druta-about" tag={Link}>
                            Meet Druta Founder
                        </DropdownItem>
                      </DropdownMenu>
                  </UncontrolledDropdown>

                  
                  
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar> 

                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://medium.com/druta-io"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-medium" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Blog
                      </span>
                    </NavLink>                    
                  </NavItem>                 
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      tag={Link}
                            to="/druta-downloads"                      
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-cloud-download mr-2" />
                      </span>
                      <span className="nav-link-inner--text ml-1">
                        Download
                      </span>
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>

        </header>
      </>);
    }
}
  
export default DrutaNavbar;