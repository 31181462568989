import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
  Button,
  Card,
  Media,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";

class DrutaAbout extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DrutaNavbar />


        <section className="section section-lg bg-gradient-primary">
          <Container className="pb-20">
            <Card className="card-profile shadow pt-5">
              <Row className="justify-content-center">
                <Col lg="4">
                  <Media>
                    <Media href="#">
                      <img
                        alt="..."
                        className="rounded-circle"
                        width="200px"
                        height="268px"
                        src={require("assets/img/tushar.jpg")}
                      />

                    </Media>
                    <Media body bottom middle>
                      <Media heading>
                          <div className="px-3">
                            Tushar Khairnar{" "}
                          </div>
                      </Media>
                        <div className="px-3">
                          Pune, MH, India
                        </div>
                      <Media>
                      <div className="px-3 py-3">
                        <a href="https://www.linkedin.com/in/tusharkhairnar">
                        <span className="btn-inner--icon">
                          <i className="fa fa-linkedin mr-2" />
                        </span>
                        </a>
                      </div>
                      <div className="px-3 py-3">
                        <a href="https://twitter.com/drutaTushar">                      
                          <span className="btn-inner--icon">
                            <i className="fa fa-twitter mr-2" />
                          </span>
                        </a>
                      </div>
                    </Media>
                    {/* <Media>
                      <div className="px-3">
                        <span className="btn-inner--icon">
                          <i className="fa fa-twitter mr-2" />
                        </span>
                      </div>
                    </Media> */}
                      </Media>
                  </Media>
                </Col>
              </Row>
              <div className="mt-5 mb-5 border-top text-center">
                <Row className="justify-content-center">
                  <Col lg="9">
                    <p>
                      Tushar Khairnar started his career as a programmer with Tata Consultancy Service and he worked in different companies like Amazon, VMWare, Gemstone Systems. He realized transformative power of Products when he built tool for testing large distributed system while working at Gemstone Systems. Exprience of conceiving product idea and iteratively building it and process of selling it to management and other teammates convienced him to work on new innovative ideas. He left his corporate job and joined a startup HooFit as CTO to build its fitness tracking and rewarding platform. Druta is his own idea to solve problems he faced while building multi-plaform mobile/web applications with limited time and budget - standard operative environment for any startup. He is geek at heart loves travelling and long driving through nature and mountains. He believes life is learning new things every day. Below is his favourite quote.
                        </p>
                    <blockquote className="blockquote">
                      <p className="mb-0">
                        “Be curious. Read widely. Try new things. What people call intelligence just boils down to curiosity.”
                          </p>
                      <footer className="blockquote-footer">
                        <cite title="Source Title">Aaron Swartz</cite>
                      </footer>
                    </blockquote>
                  </Col>
                </Row>
              </div>
            </Card>

            <Card className="card-profile shadow pt-5 bg-info mt-5 mb-5">
              <Row className="text-center justify-content-center mt-5 mb-5">
                <Col lg="10">
                  <h2 className="display-3 text-white">About Druta</h2>
                  <p className="lead text-white">
                    Idea for Druta has sprung from my previous software development efforts — the problems I faced, the things which took time longer than expected, things I had to do repeatedly, the things I wished already available, the things which I wished were leaner, simpler not bloated or unnecessarily complex. With the advent new innovation in cloud computing like Serverless and Cloud-Native stacks like Kubernetes it has become very easy to run the software.
                  </p>

                  <p className="lead text-white">But tools and frameworks dont provide right support for serverless methodology whereas cloud providers have many managed components but dont have tailored solutions for serverless. </p>

                  <p className="lead text-white">At Druta we are working on custom run-time and tools to address sepcial needs of serverless methodology to produce
something like Spring or Ruby-on-rails frameworks which offer better developer exprience and productivity</p>

                </Col>
              </Row>
              <Row className="text-center justify-content-center mt-5 mb-5">
                <Col>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="https://medium.com/druta-io/introducing-druta-io-9c89623204c4"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-medium mr-2" />
                    </span>
                    <span className="nav-link-inner--text ml-1">
                      Read more here about Why I started Druta
                      </span>
                  </Button>
                </Col>
              </Row>
            </Card>
          </Container>
          {/* SVG separator
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
        </section>
        <DrutaFooter />
      </>
    );
  }
}

export default DrutaAbout;