import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaContact from "./DrutaContact.jsx";

class Druta extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />
            <div className="position-relative">
              {/* shape Hero */}
              <section className="section section-lg section-shaped pb-250">
                <div className="shape shape-style-1 shape-default">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                <Container className="py-lg-md d-flex">
                  <div className="col px-0">
                    <Row>
                      <Col lg="6">
                        <h1 className="display-3 text-white">
                            <span className="btn-inner--icon mr-1">
                              <i className="ni ni-user-run" />
                            Acceleration Matters!{" "}</span>
                          <span>Accelerated Application Development Solutions for Serverless</span>
                        </h1>
                        <p className="lead text-white">
                        Druta provides you customizable ready-made building blocks to implement your ideas into production ready applications in days instead of weeks helping you to iterate faster and gain lesser time-to-market
                        </p>
                        <div className="btn-wrapper">
                          <Button
                            className="btn-icon mb-3 mb-sm-0"
                            color="warning"
                            tag={Link}
                            to="/druta-how"
                          >
                            <span className="btn-inner--text">How?</span>
                          </Button>
                          <Button
                            className="btn-icon mb-3 mb-sm-0"
                            color="success"
                            tag={Link}
                            to="/druta-why-serverless"
                          >
                            <span className="btn-inner--text">Why Serverless?</span>
                          </Button>
                          <Button
                            className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                            color="default"
                            tag={Link}
                            to="/druta-downloads"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="ni ni-cloud-download-95" />
                            </span>
                            <span className="btn-inner--text">
                              Download Druta
                            </span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
                {/* SVG separator */}
                <div className="separator separator-bottom separator-skew">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                  >
                    <polygon
                      className="fill-white"
                      points="2560 0 2560 100 0 100"
                    />
                  </svg>
                </div>
              </section>
            </div>

            <section className="section section-lg pt-lg-0 mt--200">
              <Container>
                <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-spaceship" />
                          </div>
                          <h3 className="text-warning text-uppercase font-weight-bold">
                            DrutaVahan
                          </h3>
                          <p className="description mt-3">
                          Toolkit to build serverless application backends rapidly like Ruby on Rails but for serverless applications on aws. At its heart, DrutaVahan is composable toolkit using which you can choose one or combine many of below blueprints or write your own services to create your own custom platform                          
                          </p>

                          <div>
                            <Badge color="success" pill className="mr-1">
                              serverless
                            </Badge>
                            <Badge color="info" pill className="mr-1">
                              framework
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              awslambda
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            tag={Link}
                            to="/druta-vahan"                            
                          >
                            Learn more
                          </Button>
                          <Button
                            className="mt-4"
                            color="warning"
                            tag={Link}
                            to="/druta-showcase#drutavahansc"
                          >
                            See in Action
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-app" />
                          </div>
                          <h3 className="text-success text-uppercase font-weight-bold">
                            Karma
                          </h3>
                          <p className="description mt-3">
                            Serverless Platform As A Service which offers you the functionality of Lambda and API Gateway with better runtime performance and developer productivity
                            <br/><br/><br/>
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              serverless
                            </Badge>
                            <Badge color="info" pill className="mr-1">
                              PAAS
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              service-mesh
                            </Badge>
                            <Badge color="secondary" pill className="mr-1 img-fluid floating">
                              #no-container
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            tag={Link}
                            to="/druta-karma"                           
                          >
                            Learn more
                          </Button>
                          <Button
                            className="mt-4"
                            color="success"
                            tag={Link}
                            to="/druta-showcase#karmasc"
                          >
                            See in Action
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>                    
                  </Row>
                </Col>
              </Row>
              </Container>
            </section>


            {/* <section className="section section-lg bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/ill/ill-2.svg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">                    
                    <h3 className="display-3 text-white">Why Druta is faster?</h3>
                    <p className="text-white">
                      Druta is written from scratch to address special needs of serverless methodology and focuses on maximum re-use of cloud provider's existing solutions
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="white"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              CLI Tools to automate devOps tasks
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="white"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Module System for Serverless</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="white"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Modules for Integrating with AWS</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="white"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Blueprints - Configurable Software Patterns
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}

 <section className="section pb-0 bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Why Druta is faster?</h4>
                      <p className="text-white">
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Software Stack Tailormade for Serverless
                          </h5>
                          <p>
                            Druta's Serverless Service is abstraction designed and written from ground-up as per serverless methodology
                          </p>
                          
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            MicroServices
                          </h5>
                          <p>
                            Druta mandates application compositions using Moduler Micro-services
                          </p>
                          
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="row-grid align-items-center">
              <Col>
              <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Module System for Serverless
                          </h5>
                          <p>
                            Modules are essential elements of any scalable software developement platform. Druta has created its own module system as per needs of serverless and cloud-native applications. Druta provides modules for many widely used AWS Middlewares                            
                          </p> 
                          <Link to="/druta-blueprints" className="text-success">                          
                            See Druta Blueprints
                          </Link>                         
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            DevOps Batteries Included
                          </h5>
                          <p>
                            Druta comes with tools for many common tasks 
                          </p>          

                          <Badge color="success" pill className="mr-1">
                              Repeated Cloud Deployments
                          </Badge>                
                          <Badge color="warning" pill className="mr-1">
                              CI/CD Integration
                          </Badge>                
                          <Badge color="default" pill className="mr-1">
                              Auto-generated Mobile and Web Client SDKs
                          </Badge>                
                          <Badge color="info" pill className="mr-1">
                              Testbench
                          </Badge>                
                          <Badge color="danger" pill className="mr-1">
                              Bootstrap Generators
                          </Badge>                                          
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
              </Col>              
              </Row>

            </Container>
      {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <br/>
          <br/>

            <DrutaContact/>

          <DrutaFooter />
        </>
      );
    }
  }
  
  export default Druta;