import React from "react";
// nodejs library that concatenates classes
// reactstrap components
// import {
//   Badge,
// } from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaDownloadView from "./DrutaDownloadView.jsx";

class DrutaDownloads extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />
            <DrutaDownloadView />            
            <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaDownloads;