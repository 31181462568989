import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import APIGateway from 'react-aws-icons/dist/aws/logo/APIGateway';
import CloudFront from 'react-aws-icons/dist/aws/logo/CloudFront';
import Cognito from 'react-aws-icons/dist/aws/logo/Cognito';
import DynamoDB from 'react-aws-icons/dist/aws/logo/DynamoDB';
import S3 from 'react-aws-icons/dist/aws/logo/S3';
import SES from 'react-aws-icons/dist/aws/logo/SES';
import SNS from 'react-aws-icons/dist/aws/logo/SES';
import SQS from 'react-aws-icons/dist/aws/logo/SQS';
import CloudWatch from 'react-aws-icons/dist/aws/logo/CloudWatch';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  Modal,
  UncontrolledTooltip
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaDownloadView from "./DrutaDownloadView.jsx";

class DrutaVahan extends React.Component {
    state = {
        exampleModal: false,
        exampleModal2: false
      };
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }

    toggleModal = state => {
        this.setState({
          [state]: !this.state[state]
        });
      };


    render() {
      return (
        <>
            <DrutaNavbar />


            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.exampleModal}
                toggle={() => this.toggleModal("exampleModal")}
                >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                    AWS Services Supported in DrutaVahan
                    </h5>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                <ul>
                    <li>Dynamodb</li>
                    <li>Cognito</li>
                    <li>SQS</li>
                    <li>SNS</li>
                </ul>
                
                </div>
                <div className="modal-footer">
                    <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                    >
                    Close
                    </Button>                    
                </div>
            </Modal>



            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.exampleModal2}
                toggle={() => this.toggleModal("exampleModal2")}
                >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel2">
                    Client Geeration in Drutavahan
                    </h5>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal2")}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                <ul>
                    <li>Java</li>
                    <li>Java-Android</li>
                    <li>JavaScript - suitable for WebApplications, ReactNative Mobile Applications</li>
                    <li>Dart - suitable for Web and mobile applications writtern using Flutter or Dart</li>
                </ul>
                
                </div>
                <div className="modal-footer">
                    <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal2")}
                    >
                    Close
                    </Button>                    
                </div>
            </Modal>

            <section className="section section-lg bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">                
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">                    
                    <h1 className="text-white">DrutaVahan</h1>
                    <p className="text-white lead">
                      Rapid Application Development Toolkit for Serverless methodlogy
                      <br/><Link to="/druta-showcase#drutavahansc"><span className="text-white">See in action</span></Link>
                    </p>                      
                  </div>
                </Col>                
              </Row>
              <Row className="row-grid align-items-center mr-1">
                    <Col className="order-md-2" md="12">               
                    <Card className="bg-default shadow border-0">
                        <CardImg
                        alt="..."
                        src={require("assets/img/druta_composition.jpg")}
                        top
                        />
                        <blockquote className="card-blockquote">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg-bg"
                            preserveAspectRatio="none"
                            viewBox="0 0 583 95"
                        >
                            <polygon
                            className="fill-default"
                            points="0,52 583,95 0,95"
                            />
                            <polygon
                            className="fill-default"
                            opacity=".2"
                            points="0,42 583,95 683,0 0,95"
                            />
                        </svg>
                        <h4 className="display-3 font-weight-bold text-white">
                            Composition System
                        </h4>
                        <p className="lead text-italic text-white">
                            Druta Composition system allows you to create your application modularly as per Serverless Philosopy of "Use, Dont Build"
                        </p>
                        </blockquote>
                        </Card>
                    </Col>
                </Row>
                <Row className="row-grid align-items-center mr-1">
                    <Col className="order-md-2" md="12">               
                    <Card className="bg-default shadow border-0">
                        <CardImg
                        alt="..."
                        src={require("assets/img/druta_platform.png")}
                        top
                        />
                        <blockquote className="card-blockquote">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg-bg"
                            preserveAspectRatio="none"
                            viewBox="0 0 583 95"
                        >
                            <polygon
                            className="fill-default"
                            points="0,52 583,95 0,95"
                            />
                            <polygon
                            className="fill-default"
                            opacity=".2"
                            points="0,42 583,95 683,0 0,95"
                            />
                        </svg>
                        <h4 className="display-3 font-weight-bold text-white">
                            AWS Modules
                        </h4>
                        <p className="lead text-italic text-white">
                            Druta provides Out-of-the-box integration with AWS Middle-ware services. These integrations are highly customisable offering you the best developer exprience
                        </p>
                        </blockquote>
                        </Card>
                    </Col>
                </Row>
                <Row className="row-grid align-items-center mr-1">
                    <Col className="order-md-2" md="12">               
                    <Card className="bg-default shadow border-0">
                        <CardImg
                        alt="..."
                        src={require("assets/img/druta_blueprints.png")}
                        top
                        />
                        <blockquote className="card-blockquote">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg-bg"
                            preserveAspectRatio="none"
                            viewBox="0 0 583 95"
                        >
                            <polygon
                            className="fill-default"
                            points="0,52 583,95 0,95"
                            />
                            <polygon
                            className="fill-default"
                            opacity=".2"
                            points="0,42 583,95 683,0 0,95"
                            />
                        </svg>
                        <h4 className="display-3 font-weight-bold text-white">
                            Blueprints
                        </h4>
                        <p className="lead text-italic text-white">
                            Embed or Extend Blueprints to bootstrap application quickly
                        </p>
                        </blockquote>
                        </Card>
                    </Col>
                </Row>
                <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <Card className="shadow shadow-lg--hover mt-2">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Serverless Module System
                          </h5>
                          <p>
                            Module system provides scalable abstraction for developers to create re-usable modules for common-use cases. Since serverless methodlogy follows <span className="primary-text font-weight-bold">"Use, Dont Build"</span> motto, module system is must-have component.
                            It is heart of DrutaVahan and provide stable basis for implementing Micro-Services
                          </p>

                          <br />
                          <br /><br />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-md-1" md="6">
                  <Card className="shadow shadow-lg--hover mt-2">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                            <h5 className="title text-success">
                              AWS Modules
                            </h5>
                            
                            <p>
                              DrutaVahan provides modules for following AWS Services in the form of services which can be configured. Modules are structured in a way that developer can pick and choose part of functionality if he does not want to use Druta's OOTB provided services
                              <br/>
                              <APIGateway size={48}/>
                              <CloudFront size={48}/>
                              <Cognito size={48}/>
                              <DynamoDB size={48}/>
                              <br/>
                              <S3 size={48}/>
                              <SES size={48}/>
                              <SNS size={48}/>
                              <SQS size={48}/>
                              <CloudWatch size={48}/>
                              {/* <Button
                                color="primary"
                                type="button"
                                onClick={() => this.toggleModal("exampleModal")}
                              >
                                Click for AWS Services
                              </Button>                         */}
                            </p>
                        </div>                                                
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
                <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <Card className="shadow shadow-lg--hover mt-2">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Microservices Modelling
                    </h5>
                          <p>
                            Everything in Druta starts from modelling services and its different data-types using Protobuf Service Descriptors. Services description and its data-types are central to Druta's deploy-time and run-time planning.
                    </p>
                          <br /><br />
                          <br /><br />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-md-1" md="6">
                  <Card className="shadow shadow-lg--hover mt-2">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Client Generation
                    </h5>
                          <p>
                            Druta can generate clients for web-applications and mobile applications right after service modelling. This allows web and mobile developers to start integrating as early as possible during development cycle.
                            <Row >
                              <Col lg="3" xs="4">                                   
                                  <img
                                    alt="..."
                                    id="tooltip255035741"
                                    className="img-fluid"
                                    src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/react.jpg"
                                  />         
                                  
                                <UncontrolledTooltip delay={0} target="tooltip255035741">
                                  ReactJS Client integrated with Redux State Management
                                </UncontrolledTooltip>
                              </Col>
                              <Col lg="3" xs="4">
                                  <div id="tooltip265846671">
                                  <img
                                    alt="vue"
                                    className="img-fluid"
                                    src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/vue.jpg"
                                  />                    
                                  </div>
                                <UncontrolledTooltip delay={0} target="tooltip265846671">
                                  VueJS Client
                                </UncontrolledTooltip>
                              </Col>
                              <Col lg="3" xs="4">
                                <div id="tooltip233150499">
                                  <img
                                    alt="..."
                                    className="img-fluid"
                                    src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/angular.jpg"
                                  />                                
                                </div>
                                <UncontrolledTooltip delay={0} target="tooltip233150499">
                                Angular Client
                                </UncontrolledTooltip>                                
                              </Col>
                            </Row >
                            <Row >    
                              <Col lg="3" xs="4">    
                                <div id="tooltip308866163">
                                <img
                                    alt="..."
                                    className="img-fluid"
                                    src={require('assets/img/icons8-android-os-96.png')}
                                    height={48} width={48}
                                  />                                
                                </div>                            
                                <UncontrolledTooltip delay={0} target="tooltip308866163">
                                  Java Client
                                </UncontrolledTooltip>
                              </Col>                              
                              <Col lg="3" xs="4"> 
                                  <div id="tooltip646643508">
                                  <img
                                    alt="..."
                                    className="img-fluid"
                                    src={require('assets/img/icons8-java-96.png')}                                    
                                    height={48} width={48}
                                  />
                                  </div>                                      
                                <UncontrolledTooltip delay={0} target="tooltip646643508">
                                  Android Client
                                </UncontrolledTooltip>
                              </Col>
                              <Col lg="4" xs="4"> 
                                  <div id="tooltip76119384">
                                  <img
                                    alt="..."
                                    className="img-fluid"
                                    src={require("assets/img/flutter.svg")}
                                  />                                
                                  </div>                                                           
                                <UncontrolledTooltip delay={0} target="tooltip76119384">
                                  Flutter Client written in Dart Language suitable for Android/iOS mobile and web applications
                                </UncontrolledTooltip>
                              </Col>
                            </Row>                            
                        {/* <Button
                              color="primary"
                              type="button"
                              onClick={() => this.toggleModal("exampleModal2")}
                            >
                              Click for Languages Supported
                        </Button> */}

                          </p>

                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <Card className="shadow shadow-lg--hover mt-2">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            OOTB Observavility
                    </h5>
                          <p>
                            All Druta Services log metrics about services and these metrics can be viewed through Druta tools. This allows developers to start looking for application metrics, workload mix and other profiling information right from developing stages. Developers can also inject their own custom metrics
                    </p>

                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-md-1" md="6">
                  <Card className="shadow shadow-lg--hover mt-2">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Testing Tools and Local Testing Support
                    </h5>
                          <p>
                            Druta provides Web based UI tool to test services in rapid manner, tool automatically create HTML form UI for you based on your service modelling schema. Druta services can be run, invoked and attached locally through Druta tools.
                    </p>

                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <DrutaDownloadView />
            <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaVahan;