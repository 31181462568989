import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaServiceModelling from "./DrutaServiceModelling.jsx";

const items = [
  {
    src: require("assets/img/karma_sarathee.png"),
    altText: "",
    caption: "Sarathee Web UI",
    header: "Sarathee Web UI"
  },
  {
    src: require("assets/img/grafana.png"),
    altText: "",
    caption: "",
    header: "Sarathee Dashboard - Monitoring Services"
  },
  {
    src: require("assets/img/grafana2.png"),
    altText: "",
    caption: "",
    header: "Sarathee Dashboard - Monitoring Resources"
  }
];

class DrutaHow extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />
            <section className="section section-lg bg-gradient-success">
            <Container className="pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="12">
                  <h2 className="display-3 text-white">Druta Methodology for Serverless Application Development</h2>
                  <p className="lead text-white">                    
                  </p>
                </Col>
              </Row>
              
              <Card className="bg-gradient-primary shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        1. Model Application into Micro-Services
                      </h3>
                      <p className="lead text-white mt-3">
                        
                      </p>                      
                      <div>
                        <DrutaServiceModelling />
                      </div>                       
                    </Col>                    
                  </Row>
                </div>
              </Card>

              <br/>

              <Card className="bg-gradient-default shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        2. Choose Services from Existing Blueprints or Compose new one
                      </h3>
                      <div style={{ "text-align" : "center"}}>
                      <img
                        alt="..."
                        className="img-fluid rounded shadow-lg"                            
                        src={require("assets/img/druta_choose_service.png")}
                      />   
                      </div>                         
                    </Col>                    
                  </Row> 
                  <Row className="align-items-center">
                    <Col>                    
                    <p className="lead text-white mt-3">
                      You can configure existing services or create one from existing service or write new one entirely from scratch
                      </p>
                    </Col>
                  </Row>                 
                </div>
              </Card>

            <br/>

              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        3. Compose Application and Build Cloud Resources
                      </h3>
                      <div style={{ "text-align" : "center"}}>
                      <img
                        alt="..."
                        className="img-fluid rounded shadow-lg"                            
                        src={require("assets/img/druta_method.png")}
                      />   
                      </div>                                       
                    </Col>                    
                  </Row>
                  <Row className="align-items-center">
                    <Col>                    
                    <p className="lead text-white mt-3">
                      You can share resources among differnt services thus composing application into logical blocks and joining them together to create one cohesive application
                      </p>
                    </Col>
                  </Row>
                </div>                

              </Card>

              <br/>

               <Card className="bg-gradient-info shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        4. Use Druta's Sarathee Tool to test and monitor application 
                      </h3>
                      <div style={{ "text-align" : "center"}}>
                        <div className="rounded shadow-lg overflow-hidden">
                          <UncontrolledCarousel items={items} />
                        </div>
                      </div> 
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col>    
                      <p className="lead text-white mt-3">                      
                      Sarathee is web based UI Testbench and monitoring tool which reads your application's service composition and types and builds out test forms and monitoring dashboard for you
                      </p>             
                    </Col>
                  </Row>
                </div>
              </Card>


              <br/>

               <Card className="bg-gradient-danger shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="text-white">
                        5. Create a production or dev deployment using Druta's CLI
                      </h3>
                      <div style={{ "text-align" : "center"}}>
                      <img
                            alt="..."
                            className="img-fluid rounded shadow-lg"                            
                            src={require("assets/img/druta_method2.png")}
                          />
                      </div>                       
                    </Col>                    
                  </Row>
                  <Row className="align-items-center">
                    <Col>    
                      <p className="lead text-white mt-3">                      
                      Use Druta's tool to create or progressively update deployment of cloud resources and serverless application through CI/CD pipeline
                      </p>             
                    </Col>
                  </Row>
                </div>
              </Card>


            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>                        
            <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaHow;