import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaContact from "./DrutaContact.jsx";

class DrutaK8s extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />


<section className="section section-lg bg-gradient-success">
            <Container className="pb-10">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Cloud-Native Application development on Kubernetes Stack</h2>
                  <p className="lead text-white">
                  Druta can help you with designing your business applications to embrace cloud-native microservices stack, help you make architectural decisions keeping cloud provider costs and other cloud concerns in mind. We can also help you to move your existing traditional monolithic server apps to cloud-native microservices stack	managed on elastic infrastructure through agile DevOps processes and continuous delivery workflows 
                  </p>

                  <p className="lead text-white">
                    With the team of strong backend developers, we build applications on kubernetes platform. We work closely with clients to understand their technology and design needs. We take care of end to end application development starting from functional and non-functional requirements to deployment. 
                  </p>

                </Col>
              </Row>              
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>         
          <DrutaContact />
            <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaK8s;