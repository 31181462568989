import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DrutaFooter from "components/druta/DrutaFooter.jsx";
import DrutaNavbar from "components/druta/DrutaNavbar.jsx";
import DrutaDownloadView from "./DrutaDownloadView.jsx";

class DrutaBlueprints extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
            <DrutaNavbar />
            <section className="section section-lg bg-gradient-warning">
            <Container>
            <Row className="row-grid align-items-center">                
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">                    
                    <h3 className="text-white">DrutaVahan Blueprints</h3>
                    <p className="text-white lead">
                    Reference implementations of some of common patterns that can help you jump-start your application
                    </p>                   
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div> */}
                          <h6 className="text-primary text-uppercase">
                            Mobile Backend
                          </h6>
                          <p className="description mt-3">
                          HTTP Endpoint which offers API to sign-up users and update, query and delete JSON-like documents securely
                          </p>
                          <br/>
                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                          CDN-Backed Static Website
                          </h6>
                          <p className="description mt-3">
                          Your own hosting solution. A simple site generator that transforms Markdown into HTML and deploys to CDN. Point your domain to CDN URL and you are live.
                          </p>
                          
                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div> */}
                          <h6 className="text-warning text-uppercase">
                            SPA/PWA with Backend
                          </h6>
                          <p className="description mt-3">
                          Single Page Applications/Progressive web application backed by CDN and integrated with backend so that you can quickly start writing awesome web applications
                          </p>
                          
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div> */}
                          <h6 className="text-primary text-uppercase">
                          Slack Bot
                          </h6>
                          <p className="description mt-3">
                          Create an End-point which allows to publish a Slack API chat postMessage message to Slack. Other DrutaVahan applications can also send messages to slack through internal messaging
                          </p>
                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                            Custom WebHooks
                          </h6>
                          <p className="description mt-3">
                          This blueprint allows you to quickly integrate with other applications using Swagger compliant API end-point
                          </p>
                          <br/> <br/>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div> */}
                          <h6 className="text-warning text-uppercase">
                            Workflow  Engine
                          </h6>
                          <p className="description mt-3">
                          Simple workflow engine which allows to define workflows, create new workflows, execute steps and query status
                          </p>
                          <br/> <br/>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div> */}
                          <h6 className="text-primary text-uppercase">
                          Custom Zapier-like workflow
                          </h6>
                          <p className="description mt-3">
                          This blueprint allows you to define workflow which accepts data from multiple external web applications and act on them
                          </p>
                          <br/>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                          Event Fan-Out
                          </h6>
                          <p className="description mt-3">
                          Fan-out incoming HTTP events to multiple sources like message queues, blob storage, relational database or document database
                          </p>
                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div> */}
                          <h6 className="text-warning text-uppercase">
                          Batch (files) processing
                          </h6>
                          <p className="description mt-3">
                          A generic backend which allows you to trigger file processing when file is uploaded
                          </p>
                          <br/>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div> */}
                          <h6 className="text-primary text-uppercase">
                            Background Jobs
                          </h6>
                          <p className="description mt-3">
                          A generic backend which accepts job and processes them in asynchronous manner. Job submitter can optionally get notified via email when job gets completed
                          </p>
                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                          Scheduled Background Job
                          </h6>
                          <p className="description mt-3">
                          Trigger Jobs at pre-defined schedule
                          </p>
                          <br/> <br/> <br/>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 mt-2">
                        <CardBody className="py-2">
                          {/* <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div> */}
                          <h6 className="text-warning text-uppercase">
                          Create your own
                          </h6>
                          <p className="description mt-3">
                          DrutaVahan is generic toolkit using which you can choose one or combine many or write your own service to create your own custom platform
                          </p>
                          <Button
                            className="mt-4"
                            color="warning"
                            tag={Link}
                            to="/druta-how"                            
                          >
                            Learn How?
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            </section>

            <DrutaDownloadView />
            <DrutaFooter />
        </>
      );
    }
  }
  
  export default DrutaBlueprints;