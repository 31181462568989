/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";

//import Index from "views/Index.jsx";
//import Landing from "views/examples/Landing.jsx";
import Druta from "views/examples/Druta.jsx";
import DrutaHow from "views/examples/DrutaHow.jsx";
//import Login from "views/examples/Login.jsx";
//import Profile from "views/examples/Profile.jsx";
//import Register from "views/examples/Register.jsx";
import DrutaDownloads from "views/examples/DrutaDownloads";
import DrutaServerless from "views/examples/DrutaServerless";
import DrutaVahan from "views/examples/DrutaVahan";
import DrutaKarma from "views/examples/DrutaKarma";
import DrutaBlueprints from "views/examples/DrutaBlueprints";
import DrutaK8s from "views/examples/DrutaK8s";
import DrutaAWS from "views/examples/DrutaAWS";
import DrutaAbout from "views/examples/DrutaAbout";
import DrutaLogin from "views/examples/DrutaLogin";
import DrutaRegister from "views/examples/DrutaRegister";
import DrutaShowcase from "views/examples/DrutaShowcase";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Druta {...props} />} />
      {/* <Route
        path="/landing-page"
        exact
        render={props => <Landing {...props} />}
      /> */}
      <Route
        path="/druta-landing-page"
        exact
        render={props => <Druta {...props} />}
      />


      <Route
        path="/druta-how"
        exact
        render={props => <DrutaHow {...props} />}
      />

      <Route
        path="/druta-why-serverless"
        exact
        render={props => <DrutaServerless {...props} />}
      />

      <Route
        path="/druta-downloads"
        exact
        render={props => <DrutaDownloads {...props} />}
      />

       <Route
        path="/druta-vahan"
        exact
        render={props => <DrutaVahan {...props} />}
      />

       <Route
        path="/druta-karma"
        exact
        render={props => <DrutaKarma {...props} />}
      />

      <Route
        path="/druta-blueprints"
        exact
        render={props => <DrutaBlueprints {...props} />}
      />

      <Route
        path="/druta-k8s"
        exact
        render={props => <DrutaK8s {...props} />}
      />

      <Route
        path="/druta-aws"
        exact
        render={props => <DrutaAWS {...props} />}
      />

       <Route
        path="/druta-about"
        exact
        render={props => <DrutaAbout {...props} />}
      />

      <Route path="/druta-login" exact render={props => <DrutaLogin {...props} />} />

      <Route
        path="/druta-register"
        exact
        render={props => <DrutaRegister {...props} />}
      />

      <Route
        path="/druta-showcase"
        exact
        render={props => <DrutaShowcase {...props} />}
      />
      
      {/* <Route path="/login-page" exact render={props => <Login {...props} />} />
      <Route
        path="/profile-page"
        exact
        render={props => <Profile {...props} />}
      />
      <Route
        path="/register-page"
        exact
        render={props => <Register {...props} />}
      />  */}
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
