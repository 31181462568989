import React from "react";
// nodejs library that concatenates classes
import Highlight from 'react-highlight.js'
import { useState } from 'react';
// reactstrap components
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";

const dataTypes= '\
message Jobs {\n \
  string id = 1;\n \
  int64 createTime = 2;\n \
  int64 startTime = 3;\n \
  int64 endTime = 4;\n \
  string type = 5;\n \
  //this is to indicate if job is completed or not. Error in indicated in statusCode field\n \
  string status = 6;\n \
  //non zero is error\n \
  int32 statusCode = 7;\n \
  string params = 8;\n \
  string output = 9;\n \
  \n \
}\n \
';

const serviceDefinition = '\
service JobsService {\n \
  rpc init(ServiceConf) returns (google.protobuf.Empty) {}\n \
  \n \
  rpc getDeployment(google.protobuf.Empty) returns (DeployConfiguration) {}\n \
  \n \
  rpc getDescription(google.protobuf.Empty) returns (VahanServiceDescription) {}\n \
  \n \
  rpc submit(JobCreateRequest) returns (JobCreateResponse) {}\n \
  \n \
  rpc enqueue(JobCreateRequest) returns (JobCreateResponse) {}\n \
  \n \
  rpc get(GetJobStatus) returns (GetJobResponse) {}\n \
  \n \
}\n \
';

const requestResponse = '\
enum JobType {\n \
  UNKNOWN = 0;\n \
  FIBONACCI = 1;\n \
}\n \
message JobCreateRequest {\n \
  JobType type = 1;\n \
  map<string,string> params = 2;\n \
}\n \
\n \
message JobCreateResponse {\n \
  bool error = 1;\n \
  string message = 2;\n \
  string jobId = 3;\n \
}\n \
';

const reqResponseHightLight = (<div key="reqResponseHightLight"> <Highlight language='protobuf'>
{requestResponse}
</Highlight></div>);

const serviceDefinitionHightLight = (<div key="serviceDefinitionHightLight"><Highlight language='protobuf'>
{serviceDefinition}
</Highlight></div>);

const dataTypesHightLight = (<div key="dataTypesHightLight"><Highlight language='protobuf'>
{dataTypes}
</Highlight></div>);


const items = [
    {
        src: serviceDefinitionHightLight,
        key:'serviceDefinitionHightLight',
        altText: 'Slide 2',
        caption: 'Service Definition'
      },
      {
        src: dataTypesHightLight,
        key:'dataTypesHightLight',
        altText: 'Slide 3',
        caption: 'Data Models'
      },
    {
      src: reqResponseHightLight,
      key:'reqResponseHightLight',
      altText: 'Slide 1',
      caption: 'Request Response Messages'
    }
  ];


const DrutaServiceModelling = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    }
  
    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.key}
        >
          {item.src}
          <CarouselCaption captionText={item.caption}  />
        </CarouselItem>
      );
    });   

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
        
}


export default DrutaServiceModelling;